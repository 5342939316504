// Unauthenticated
.home-slider-block {
  float: left;
  width: 100%;
  padding: 60px 15px;
  text-align: center;
  border-radius: 0;
  margin: 0;
  position: relative;
  background: #fff;
}

.home-slider-block h2 {
  font-size: 72px;
  font-family: var(--kg-primary-font);
  color: var(--kg-primary);
  display: table;
  width: 100%;
  margin: 0;
  margin-bottom: 12px;
  font-size: clamp(35px, 6vw, 72px);
  padding: 0px;
  font-weight: 600;
  background: var(--linear-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-slider-block p {
  font-size: clamp(16px, 2vw, 22px);
  color: #000;
  display: table;
  font-family: var(--kg-primary-font);
  width: 100%;
  font-weight: 400;
  margin: 15px 0 0;
  padding: 0px;
}

//Authenticated

.userhomepgcontent .home-slider-block {
  background: transparent;
  padding-top: 30px;
}

.userhomepgcontent .home-slider-block h2 {
  font-size: 48px;
  line-height: 60px;
}

.userhomepgcontent .home-slider-block p {
  font-size: 22px;
  line-height: 32px;
  color: #4b5563;
  margin-top: 7px;
}

@media (max-width: 991px) {
  .home-slider-block {
    padding-bottom: 0px;
  }
}

@media (max-width: 767px) {
  .userhomepgcontent .home-slider-block {
    padding: 30px clamp(0px, 1vw, 15px) 0px clamp(0px, 1vw, 15px);
  }

  .userhomepgcontent .home-slider-block h2 {
    font-size: clamp(26px, 5.9vw, 35px);
  }
}

@media (max-width: 480px) {
  .home-slider-block h2 {
    font-size: clamp(24px, 7vw, 35px);
  }
}

@media (max-width: 420px) {
  .userhomepgcontent .home-slider-block {
    padding: 30px 0 0 0;
  }
}
