.uploadpanel-outer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.uploadpanel-inputblock {
}

.uploadpanel-inputblock > div {
  color: #6b7280;
  font-weight: 600;
}

.uploadpanel-inputblock label {
  display: block;
  margin-top: 12px;
}

.uploadpanel-inputblock button {
  width: 100%;
  border-radius: 12px;
}

.uploadpanel-inputblock #input-file {
  display: none;
}

.uploadpanel-inputblock.clipartupload-inputblock label {
  width: 100%;
  margin: 0 0 10px;
}
.uploadpanel-inputblock.clipartupload-inputblock label button.bp5-button {
  border: solid 1px #686868 !important;
}
.uploadpanel-inputblock.clipartupload-inputblock label .form-control {
  background-color: #0e1318;
  border: solid 1px #686868 !important;
  padding: 7px;
  border-radius: 2px;
  color: #fff !important;
  box-shadow: none !important;
}
.uploadpanel-inputblock.clipartupload-inputblock
  label
  .form-control::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #fff;
}
.uploadpanel-inputblock.clipartupload-inputblock
  label
  .form-control::placeholder {
  color: #fff;
}
.uploadpanel-inputblock.clipartupload-inputblock .btn.btn-primary {
  background: var(--linear-gradient) !important;
  border-radius: 2px;
  border: 0;
  box-shadow: none;
  font-size: 1rem;
  padding: 8px 10px;
  color: #fff !important;
  opacity: 1;
}
.uploadpanel-inputblock.clipartupload-inputblock
  .btn
  .bp5-spinner
  .bp5-spinner-track {
  stroke: rgba(255, 255, 255, 0.3);
}
.uploadpanel-inputblock.clipartupload-inputblock
  .btn
  .bp5-spinner
  .bp5-spinner-head {
  stroke: rgb(255, 255, 255);
}

.uploadpanel-section {
  padding-top: 10px;
  height: 100%;
}

.uploadpanel-section-innerblock {
  height: calc(100% - 120px);
}

.preview-clipartfile {
  width: 100%;
  position: relative;
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff1f !important;
  border-radius: 3px !important;
}
.preview-clipartfile span {
  position: unset !important;
  float: left;
}
.preview-clipartfile-img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}
.preview-clipartfile button {
  position: absolute;
  top: 0;
  margin: auto;
  right: 0;
  cursor: pointer;
  border: 0px;
  padding: 0;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  bottom: 0;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: none;
}
.preview-clipartfile:hover button {
  display: block;
}
.preview-clipartfile .fa {
  color: red;
  font-size: 17px;
}

.upload-images-wrapper {
  overflow: auto;
  min-height: 100%;
}

.upload-images-wrapper .go4160152499 {
  scrollbar-width: thin;
}

.upload-images-wrapper .credit,
.upload-videos-wrapper .credit {
  background: none;
  top: 0;
  left: unset;
  right: 0;
  width: min-content;
  height: min-content;
  padding: 4px;
}

.upload-images-wrapper .go1434738792 {
  width: unset;
  height: unset;
}

.upload-audio-wrapper .audio-file-container {
  position: relative;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 4px;
}

.upload-audio-wrapper .audio-file-popup {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 4px;
}

.upload-audio-wrapper .audio-file-container:hover {
  background: #ebecef;
}

.upload-audio-wrapper .audio-file-name {
  display: inline-block;
  vertical-align: top;
}

.upload-audio-wrapper .audio-file-name div:nth-of-type(1) {
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-weight: 400;
  width: 230px;
  margin-top: 2px;
}

.upload-audio-wrapper .audio-file-name div:nth-of-type(2) {
  font-size: 12px;
  margin-top: 4px;
  font-weight: 400;
  color: var(--editor-border);
}

.upload-audio-wrapper .audio-icon {
  padding: 20px;
  border-radius: 4px;
  background-color: rgb(213 221 232);
  margin-right: 12px;
}

.upload-img-hover-btn {
  background: white;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 8px;
  padding: 2px;
}

.upload-img-btn-container {
  background: #f3f4f6;
  color: #333d4a;
  padding: 4px;
}

.upload-img-btn-container > button {
  padding: 8px;
  width: 100%;
  text-align: left;
}

.upload-img-btn-container > button:hover {
  background: white;
}

.upload-img-btn-container > button > span {
  vertical-align: middle;
}

.upload-img-btn-container > button > span:nth-of-type(1) {
  margin: 10px;
}

.upload-img-btn-container > button > span:nth-of-type(2) {
  margin-left: 8px;
}

.button-outlined.upload-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  .button-outlined-icon {
    margin-right: 10px;
    z-index: 1;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    .button-outlined-icon {
      svg {
        stop {
          stop-color: #fff;
        }
      }
    }

    &:active {
      transform: scale(0.95);
    }
  }

  span {
    text-transform: initial !important;
    font-weight: 600;
  }
}

.button-outlined.upload-button::before {
  border-radius: 8px;
}

.uploadpanel-inputblock .upload-section-tablist {
  border: none;
  margin: 16px 0;
}

.uploadpanel-inputblock .upload-section-tablist > button {
  font-weight: 600;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 0;
}

@keyframes fillFromBottom {
  0% {
    background: linear-gradient(0deg, var(--kg-primary), 0%, rgba(0, 0, 0, 0));
  }
  10% {
    background: linear-gradient(0deg, var(--kg-primary), 10%, rgba(0, 0, 0, 0));
  }
  20% {
    background: linear-gradient(0deg, var(--kg-primary), 20%, rgba(0, 0, 0, 0));
  }
  30% {
    background: linear-gradient(0deg, var(--kg-primary), 30%, rgba(0, 0, 0, 0));
  }
  40% {
    background: linear-gradient(0deg, var(--kg-primary), 40%, rgba(0, 0, 0, 0));
  }
  50% {
    background: linear-gradient(0deg, var(--kg-primary), 50%, rgba(0, 0, 0, 0));
  }
  60% {
    background: linear-gradient(0deg, var(--kg-primary), 60%, rgba(0, 0, 0, 0));
  }
  70% {
    background: linear-gradient(0deg, var(--kg-primary), 70%, rgba(0, 0, 0, 0));
  }
  80% {
    background: linear-gradient(0deg, var(--kg-primary), 80%, rgba(0, 0, 0, 0));
  }
  90% {
    background: linear-gradient(0deg, var(--kg-primary), 90%, rgba(0, 0, 0, 0));
  }
  100% {
    background: linear-gradient(
      0deg,
      var(--kg-primary),
      100%,
      rgba(0, 0, 0, 0)
    );
  }
}

.uploadTabContainer {
  transform-origin: bottom;
  transition: fill 0.3s ease-in-out;
}
