.cat-filter {
  float: left;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 43px;
  position: relative;
  display: flex;
  align-items: center;
}

.cat-filter-single span {
  margin: 0 5px 0 0;
  font-size: 18px;
  line-height: 21px;
  font-family: var(--kg-primary-font);
  color: #000;
  font-weight: 400;
}

.cat-filter-single {
  min-width: 190px;
  max-width: 250px;
  border-radius: 5px;
  height: 50px;
  margin: 0 25px 0 0;
  position: relative;
}

.cat-filter-single > span::after {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #000;
  position: absolute;
  content: '';
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.cat-filter-single > span {
  float: left;
  width: 100%;
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(to right, var(--kg-secondary), var(--kg-primary)) border-box;
  border: 1px solid transparent;
  border-radius: 6px;
  height: 48px;
  padding: 10px 45px 10px 15px;
  font-size: 16px;
  line-height: 28px;
  font-family: var(--kg-primary-font);
  color: #000;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
  position: relative;
  z-index: 99;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cat-filter-single ul {
  padding: 4px 0 0;
  position: absolute;
  right: 0;
  left: 0;
  margin: -6px auto 0;
  width: 100%;
  border: 1px solid transparent;
  border-top: 0px;
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(to right, var(--kg-secondary), var(--kg-primary)) border-box;
  border-radius: 0 0 6px 6px;
  top: calc(100%);
  z-index: 98;
  opacity: 1;
}

.cat-filter-single.opened ul {
  opacity: 1;
}

.cat-filter-single ul li {
  padding: 12px 15px;
  margin: 0;
  width: 100%;
  display: flex;
  position: relative;
  font-size: 16px;
  line-height: 27px;
  font-family: var(--kg-primary-font);
  color: #000 !important;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(to right, var(--kg-secondary), var(--kg-primary)) border-box;
}
.cat-filter-single ul li:last-child {
  border-radius: 6px 6px;
  margin-bottom: -1px;
}

.free-filter-single {
  min-width: 190px;
  max-width: 250px;
  border-radius: 5px;
  height: 50px;
  margin: 0 25px 0 0;
  position: relative;
}

.free-filter-single > div {
  float: left;
  width: 100%;
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(to right, var(--kg-secondary), var(--kg-primary)) border-box;
  border: 1px solid transparent;
  border-radius: 6px;
  height: 48px;
  padding: 10px 45px 10px 15px;
  font-size: 16px;
  line-height: 28px;
  font-family: var(--kg-primary-font);
  color: #000;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
  position: relative;
  z-index: 99;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CheckboxRoot {
  background-color: white;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px var(--black-a7);
  border: 2px solid #818181;
}

.CheckboxRoot span {
  margin: 0;
  padding: 2px;
}

.CheckboxRoot[aria-checked='true'],
.CheckboxRoot[data-checked] {
  background: var(--linear-gradient);
  border: 0px solid transparent;
}

.CheckboxRoot:hover {
  background-color: var(--violet-3);
}

.CheckboxIndicator svg {
  width: 12px;
  height: 12px;
}

.Label {
  padding-left: 10px;
  font-size: 16px;
}

@media (max-width: 767px) {
  .cat-filter {
    gap: 10px;
    justify-content: flex-start;
  }
  .cat-filter > span {
    margin: 0;
    width: 100%;
  }
  .cat-filter-single,
  .free-filter-single {
    width: 48% !important;
    margin: 15px 0 0;
  }
  .cat-filter-single,
  .free-filter-single {
    min-width: unset !important;
    flex: 3;
  }
}

@media (max-width: 580px) {
  .cat-filter-single span {
    font-size: 12px;
  }

  .free-filter-single label {
    font-size: 13px;
  }

  .cat-filter-single span::after {
    right: 10px;
  }
}

@media (max-width: 480px) {
  .cat-filter-single span {
    font-size: 12px;
  }

  .free-filter {
    font-size: 12px;
  }
}

@media (max-width: 435px) {
  .cat-filter-single span {
    font-size: 10px;
  }

  .free-filter {
    font-size: 11px;
  }
}

@media (max-width: 420px) {
  .cat-filter-single span {
    font-size: 9px;
  }

  .free-filter .free-filter-li,
  .cat-filter-single ul li {
    font-size: 10px;
  }
}

@media (max-width: 360px) {
  .cat-filter-single span {
    font-size: 9px;
  }

  .free-filter .free-filter-li {
    font-size: 9px;
  }

  .cat-filter-single span::after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
}

@media (max-width: 435px) {
  .cat-filter-single span {
    font-size: 10px;
  }

  .free-filter .free-filter-li {
    font-size: 11px;
  }
}

@media (max-width: 767px) {
  .cat-filter {
    gap: 10px;
    justify-content: flex-start;
  }
  .cat-filter > span {
    margin: 0;
    width: 100%;
  }
  .cat-filter-single {
    width: 48% !important;
    margin: 15px 0 0;
  }

  .cat-filter-single {
    min-width: unset;
    flex: 3;
  }

  .free-filter {
    flex: 2;
  }
}

@media (max-width: 580px) {
  .cat-filter-single > span {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 0px;
  }

  .free-filter .free-filter-li {
    font-size: 12px;
  }

  .cat-filter-single span::after {
    right: 10px;
  }
}

@media (max-width: 480px) {
  .cat-filter-single span {
    font-size: 12px;
  }

  .free-filter .free-filter-li {
    font-size: 12px;
  }
}

@media (max-width: 420px) {
  .cat-filter-single span {
    font-size: 9px;
  }

  .free-filter .free-filter-li,
  .cat-filter-single ul li {
    font-size: 10px;
  }
}

@media (max-width: 360px) {
  .cat-filter-single span {
    font-size: 9px;
  }

  .free-filter .free-filter-li {
    font-size: 9px;
  }

  .cat-filter-single span::after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
}

@media (max-width: 340px) {
  .cat-filter-single span {
    font-size: 8px;
  }
}
