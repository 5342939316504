.polotno-panel-container:has(.effects-section) {
  overflow-y: auto;
  scrollbar-color: #e5e7eb #9ca3af;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e7eb;
  }

  &::-webkit-scrollbar-thumb {
    background: #9ca3af;
  }
}

.effects-tiles-container {
  .effects-tile {
    border: 1px solid #d1d5db;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 12px;
    transition: 0.25s;

    &:hover {
      cursor: pointer;
      border: 1px solid #b6bcc5;
    }

    &.selected {
      border: 1px solid var(--kg-primary);
      box-shadow:
        0 1px 3px 0 rgb(0 0 0 / 0.1),
        0 1px 2px -1px rgb(0 0 0 / 0.1);
    }
  }
  .effects-tile-top {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .effects-text {
    font-weight: 500;
  }

  .effects-action {
    margin-left: auto;

    .checkbox-root {
      background-color: white;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d1d5db;
      padding: 2px;
    }

    .checkbox-root[aria-checked='true'],
    .checkbox-root[data-checked] {
      border: 1px solid var(--kg-primary);

      .checkbox-indicator {
        width: 100%;
        height: 100%;
        background: var(--linear-gradient);
        border-radius: 2px;
      }
    }
  }

  .effects-tile-bottom {
    margin-top: 10px;
    margin-bottom: 8px;
    .slider-header-text {
      font-size: 12px;
      font-weight: 400;
      color: #4b5563;
    }

    .effects-color-picker {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
      > span > div,
      > span > div > div {
        width: 50px !important;
        height: 28px !important;
        border-radius: 8px !important;
      }
    }
  }
}
