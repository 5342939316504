.custom-toolbar-container {
  max-height: 50px;

  .overflow-list {
    display: inline-flex;
    gap: 5px;
  }
}

.custom-toolbar-left {
  max-height: 50px;
  overflow: hidden;
  max-width: 700px;
}

.custom-toolbar-right {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.toolbar-popover {
  background: #fff;
  padding: 0 5px;
}

.toolbar-font-family {
  border-radius: 8px !important;
  margin: 0 5px !important;

  button {
    border: 1px solid #d1d5db !important;
    height: 35px !important;
    margin: 0 !important;
    min-height: unset !important;
    max-height: unset !important;
  }
}

.show-more-popover {
  width: auto !important;
}

.toolbar-num-input {
  display: flex;
  height: 35px;
  max-width: 100px;
  .input-stepper {
    padding: 5px 8px;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d1d5db;
    height: 35px;
    cursor: pointer;
    transition: 0.25s;

    &:hover {
      background: #edf2f7;
    }

    &.left {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right: none;
    }
    &.right {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: none;
    }
  }
}

.bp5-menu-item > .bp5-fill {
  text-transform: capitalize;
}

@media (max-width: 800px) {
  .custom-toolbar-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    // overflow-x: scroll;
    & + .bp5-align-right {
      padding: 0 10px;
    }
  }
  .custom-toolbar-right {
    float: unset !important;
  }
  .custom-toolbar-left {
    overflow: visible;
    width: unset !important;
    float: unset !important;
  }
}
