@use '../utilities/mixins' as mixins;

.pgheading {
  font-size: 30px;
  line-height: 36px;
  color: #000;
  font-weight: 500;
  float: left;
  font-family: var(--kg-primary-font);
  width: 100%;
  margin: 0 0 30px;
}

.pg-content-outer {
  padding: 40px 0;
}

.pg-white-container {
  min-height: 100vh;
  background: #fff;
  padding: 40px 15px;
}

.pg-content-outer .terms-title {
  color: #707070;
  margin: 15px 0;
  margin-top: 50px;
}

.pg-content {
  float: left;
  width: 100%;
}

.pg-content h1,
.pg-content h2,
.pg-content h3,
.pg-content h4,
.pg-content h5,
.pg-content h6 {
  @include mixins.gradientText;
  font-weight: 500;
  font-family: var(--kg-primary-font);
  display: table;
  width: 100%;
}

.pg-content p,
.pg-content li {
  font-size: 15px;
  line-height: 26px;
  color: #707070;
  font-family: var(--kg-primary-font);
}
.pg-content .font18 {
  font-size: 18px;
}
.pg-content a {
  color: #3f3e80;
}

.section-container {
  max-width: 1440px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
  padding-bottom: 100px;
  padding-top: 100px;
}

.section-container .text-container {
  width: 40%;
  padding: 15px;
}
.section-container .image-container {
  width: 60%;
  position: relative;
}

.section-container .image-container::before {
  opacity: 0.3;
  border-radius: 50%;
  background: radial-gradient(
    71.4% 71.4% at 61.95% 29.61%,
    var(--kg-secondary) 0%,
    var(--kg-primary) 100%
  );
  background-size: auto;
  height: 144px;
  width: 144px;
  position: absolute;
  content: '';
  top: -20px;
  left: -100px;
  background-position: right;
}

.section-container .image-container::after {
  position: absolute;
  background-image: url(/images/homepage/waves.png);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: right;
  height: 22px;
  left: -100px;
  content: '';
  top: 80px;

  position: absolute;
  right: -20px;
  background-image: url(/images/homepage/waves.png);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: right;
  height: 22px;
  left: -250px;
  content: '';
}

.section-container .image-container::after {
  opacity: 1;
  width: 200px;
  /* background-image: url('/images/homepage/waves-gradient.svg'); */
  position: absolute;
  content: '';
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  height: 40px;
}

.section-container .image-container .partners-card {
  padding: 70px 30px;
  background: #fff;
  width: 100%;
  border-radius: 32px;
  z-index: 5;
  position: relative;
}

.section-container .image-container .partners-card .card-text {
  text-align: center;
  font-size: clamp(1rem, 0.75rem + 0.8333vw, 1.5rem);
  font-weight: 500;
}

.section-container .image-container .card-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 75%;
  max-width: 250px;
  text-align: center;
  margin: 15px auto;
}

.section-container .text-container {
  flex-shrink: 0;
}

.section-container .text-container h4 {
  color: #000;
  font-size: 62px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  font-family: var(--kg-primary-font);
  width: 100%;
  float: left;
  margin: 0 0 13px;
}

@media (max-width: 420px) {
  .pgheading {
    font-size: 30px;
  }
}
