.kg-features {
  float: left;
  width: 100%;
  padding: 25px;
  margin-bottom: 100px;
  background: #fafafa;
}

.kg-features .col-md-12 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.kg-feature-single {
  float: left;
  width: 48%;
  background: #fff;
  padding: 50px;
  text-align: center;
  border-radius: 10px;
}

.kg-feature-single:last-child {
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(to right, var(--kg-secondary), var(--kg-primary)) border-box;
  border: 2px solid transparent;
}

.kg-feature-single h4 {
  font-size: 40px;
  line-height: 40px;
  font-family: var(--kg-primary-font);
  color: #000;
  float: left;
  width: 100%;
  margin: 0 0 20px;
  padding: 0px;
  font-weight: 500;
  align-items: center;
  display: flex;
  justify-content: center;
}

.kg-feature-single h4 svg {
  margin-right: 10px;
  height: 50px;
  object-fit: contain;
}

.kg-feature-single p {
  font-size: 24px;
  line-height: 34px;
  font-family: var(--kg-primary-font);
  font-weight: 400;
  color: #000;
  float: left;
  width: 100%;
  margin: 0;
  padding: 0px;
  text-align: center;
}

.kg-feature-single p:last-child {
  text-align: center;
}

.kg-feature-single ul {
  padding: 0;
  list-style: none;
  text-align: left;
  border-top: 2px solid #ededed;
  float: left;
  width: 100%;
  margin: 25px 0 40px;
  display: flex;
  flex-wrap: wrap;
}

.kg-feature-single ul li {
  font-size: 24px;
  line-height: 34px;
  font-family: var(--kg-primary-font);
  color: #000;
  float: left;
  width: 100%;
  margin: 0;
  padding: 25px 0 0;
  align-items: center;
  display: flex;
}

.kg-feature-single ul li > span {
  margin-right: 17px !important;
  min-width: 31px;
}

.kg-feature-single ul li > .empty-image {
  display: block;
  width: 26px;
}

.kg-feature-single label {
  margin: 0 0 30px;
  position: relative;
}

.kg-feature-single label::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(to right, var(--kg-secondary), var(--kg-primary)) border-box;
  border: 3px solid transparent;
  border-radius: 10px;
}

.kg-feature-btn {
  display: flex;
  justify-content: center;
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

.kg-feature-btn .button-outlined,
.kg-feature-btn .button-contained {
  padding: 12px 20px;
}

.kg-feature-singleouter {
  width: 90%;
  margin: 50px 5% 0;
  display: table;
  position: relative;
}

.kg-feature-single.userhome-tokgpro {
  width: 100%;
  position: relative;
  z-index: 9;
}

.kg-feature-singleouter .background {
  content: '';
  width: 79px;
  height: 73px;
  right: -26px;
  top: -28px;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: auto;
  position: absolute;
}

.kg-feature-singleouter .dot-box {
  content: '';
  width: 111px;
  height: 99px;
  left: -43px;
  bottom: -32px;
  background-image: url('/images/homepage/userhome-kgproblock2.png');
  background-position: left top;
  background-repeat: no-repeat;
  background-size: auto;
  position: absolute;
}

.kg-feature-single.userhome-tokgpro p {
  font-size: 20px;
  line-height: 31px;
  text-align: center;
}

.kg-feature-single.userhome-tokgpro .btn-container {
  float: left;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 30px 0 0;
}

.kg-feature-single.userhome-tokgpro .btn-container .button-contained,
.kg-feature-single.userhome-tokgpro .btn-container .button-outlined {
  padding: 14px 30px;
}

.kg-feature-single.userhome-tokgpro ul {
  border-top: 0px;
  margin: 10px 0 20px;
}
.kg-feature-single.userhome-tokgpro ul li {
  width: 33.3%;
  display: inline;
  text-align: center;
  font-size: 20px;
  line-height: 31px;
  padding: 25px 20px 0;
}

.kg-feature-single.userhome-tokgpro ul li > span {
  margin: 0px auto 10px !important;
  display: table !important;
}

.kg-feature-single.userhome-tokgpro ul li > .empty-image {
  height: 28px;
  margin: 0px auto 10px !important;
  display: table !important;
}

.kg-feature-single.userhome-tokgpro ul li label {
  margin: 0;
  font-size: 12px;
  line-height: 19px;
  float: left;
  width: 100%;
}

.kg-feature-single.userhome-tokgpro ul li label::before {
  display: none;
}

.pro-price-text {
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  &.top {
    margin-top: 16px;
  }

  &.bottom {
    margin-bottom: 20px;
  }
}

@media (max-width: 1200px) {
  .kg-features .col-md-12 {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .kg-feature-single p,
  .kg-feature-single ul li {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .kg-feature-single {
    padding: 30px 20px;
  }

  .kg-feature-single p,
  .kg-feature-single ul li {
    font-size: 15px;
    line-height: 25px;
  }
}

@media (max-width: 767px) {
  .kg-feature-single {
    width: 100%;
    margin: 20px 0 0;
  }

  .kg-feature-singleouter {
    width: 100%;
    margin: 50px 0 0;
  }

  .kg-feature-single.userhome-tokgpro {
    width: 100%;
    margin: 0;
  }

  .kg-feature-single.userhome-tokgpro ul li {
    width: 50%;
  }

  .kg-feature-single .btn {
    font-size: 19px;
    line-height: 19px;
  }
}

@media (max-width: 420px) {
  .kg-feature-single.userhome-tokgpro ul li {
    width: 100%;
  }
}
